<template>
  <div>
    <b-card class="w-100 d-flex rounded-lg">
      <b-row v-if="user">
          <b-col md="12" class="mb-2">
            <span style="font-size: 1.3rem">Assign Role to {{user.fname}} {{user.lname}}</span>
          </b-col>
          <b-col md="4">
            <h6>Roles</h6>
            <validation-observer
              class="w-100"
              ref="roleForm"
              #default="{invalid}"
            >
              <b-form-group
                label-for="roles"
              >
                <validation-provider
                  #default="{ errors }"
                  name="roles"
                  vid="roles"
                  rules="required"
                >
                  <v-select
                    v-model="selected"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="roles"
                    :reduce="val => val.name"
                  >
                    <template #option="{ name }">
                      <span class="ml-50"> {{ name }}</span>
                    </template>

                    <template #selected-option="{ name }">
                      <span class="ml-50"> {{ name }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
          </b-col>
      </b-row>
      <b-button variant="primary ml-0 mt-1" @click="submit()">Assign</b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    required
  },
  directives: {
    Ripple,
  },
  computed:{
    ...mapGetters({
      user: "users/user",
      roles: "users/roles"
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    }
  },
  data() {
    return {
      selected: null,
    }
  },
  methods:{
    init(){
      this.$store.dispatch('users/showUser', this.id).then(_=>{
        this.$store.dispatch('users/listRoles').then(_=>{
          this.selected = this.user.roles
        })
      })
    },
    submit(){
      this.$refs.roleForm.validate().then(success => {
        if (success) {
          const payload = {id: this.id ,  query: {roles_name: this.selected} }
            this.$store.dispatch( 'users/assignRole' , payload ).then(res=>{
              this.$router.push({name:'users'})
            }).catch(error => {
              console.log('--------------------error',error);
              
              if (error.response) {
                console.log('--------------------error1111111111111',error);
                this.$swal({
                  icon: 'error',
                  title: `<h4>${error.response.data.error}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: '#E84185',
                  allowOutsideClick: true
                });
              }
          });
        }
      });
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
.btn-primary{
  border-color: #E84185 !important;
  background-color: #E84185 !important;
  box-shadow: #E84185 !important;
}
.btn-primary:focus{
  border-color: #E84185 !important;
  background-color: #E84185 !important;
  box-shadow: #E84185 !important;
}
.btn-primary:hover{
  box-shadow: #E84185 !important;
}
</style>
