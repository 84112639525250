var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"w-100 d-flex rounded-lg"},[(_vm.user)?_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('span',{staticStyle:{"font-size":"1.3rem"}},[_vm._v("Assign Role to "+_vm._s(_vm.user.fname)+" "+_vm._s(_vm.user.lname))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v("Roles")]),_c('validation-observer',{ref:"roleForm",staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"label-for":"roles"}},[_c('validation-provider',{attrs:{"name":"roles","vid":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roles,"reduce":function (val) { return val.name; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}}],null,false,4161997533)})],1)],1):_vm._e(),_c('b-button',{attrs:{"variant":"primary ml-0 mt-1"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Assign")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }